<template>
  <div class="ticket">
    <d-search :searchData="searchData" @searchEvent="searchEvent" @resetEvent="resetEvent" />
    <div class="content">
      <d-lable class="contentTable" :tableData='tableData' :columnData='columnData'>
        <template v-slot:status="{ row }">
          <span v-if="row.status === 0" class="blue">预生成</span>
          <span v-if="row.status === 1" class="blue">成功</span>
          <span v-if="row.status === 2" style="color: #F56C6C;">失败</span>
        </template>
        <template v-slot:businessStatus="{ row }">
          <span v-if="row.businessStatus === 0" class="blue">处理中</span>
          <span v-if="row.businessStatus === 1" class="blue">成功</span>
          <span v-if="row.businessStatus === 2" style="color: #F56C6C;">失败</span>
        </template>
        <template v-slot:action="{ row }">
          <span class="mgr10 blue" @click="handleClickAction(row.id)" v-if="row.status === 1 && row.businessStatus === 2">重新充值</span>
        </template>
      </d-lable>
    </div>
    <d-paging :pager="pager" @change="getItemList" />
  </div>
</template>

<script>
import DSearch from "@/components/d-search";
import DLable from "@/components/d-table";
import DPaging from "@/components/d-paging";
import moment from 'moment'
import { filterDayDate, disabledDayDate } from "@/utils/utils";

const statusOptions = [
  {
    value: 0,
    label: '预生成'
  },
  {
    value: 1,
    label: '成功'
  },
  {
    value: 2,
    label: '失败'
  },
]

const businessStatusOptions = [
  {
    value: 0,
    label: '处理中'
  },
  {
    value: 1,
    label: '成功'
  },
  {
    value: 2,
    label: '失败'
  },
]
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    DSearch,
    DLable,
    DPaging,
  },
  data () {
    return {
      childList: [],
      searchData: {
        searchItem: [
          {
            label: '选择店铺',
            value: 'shopIds',
            type: 'linkageSelect',
            placeholder: '',
            options: [],
            filterable: true,
            optionProps: {
              value: 'id',
              label: 'name',
              children: 'childList'
            }
          },
          {
            label: "充值人",
            value: "userName",
            type: "commonInput",
          },
          {
            label: "充值状态",
            value: "status",
            type: "commonSelect",
            placeholder: "",
            options: statusOptions
          },
          {
            label: "业务状态",
            value: "businessStatus",
            type: "commonSelect",
            placeholder: "",
            options: businessStatusOptions
          },
          {
            label: '充值时间',
            value: 'date',
            type: 'commonDatetime',
            startPlaceholder: "开始时间",
            endPlaceholder: "结束时间",
            defaultTime: ['00:00:00','23:59:59']
          },
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true,
        },
        params: {
          shopIds: [],
          date: ['',''],
          userName: '',
          status: '',
          businessStatus: '',
        },
      },
      columnData: [
        { prop: 'shopName', label: '店铺名称' },
        { prop: 'orderNo', label: '订单号' },
        { prop: 'businessName', label: '业务名称' },
        { prop: 'userName', label: '充值人' },
        { prop: 'money', label: '充值金额' },
        { prop: 'status', label: '充值状态', slotProp: true },
        { prop: 'createTime', label: '充值时间' },
        { prop: 'businessStatus', label: '业务状态', slotProp: true },
        { prop: 'updateTime', label: '更新时间' },
        { prop: 'action', label: '操作', slotProp: true },
      ],
      tableData: [],
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
    };
  },
  created () {
    this.setInitDate()
    this.searchEvent()
    this.getShopList()
  },
  methods: {
    handleClickAction (id) {
      this.$api.merchant.payLogNotifyApi(id).then(res => {
        if (res.code === 200) {
          this.$message.success(res.msg)
          this.getItemList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    setInitDate () {
      // const endDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss')
      // const startDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      // this.searchData.params.date = [startDate, endDate]
    },
    searchEvent () {
      this.pager.page = 1
      this.pager.rows = 10
      this.getItemList()
    },

    resetEvent () {
      this.setInitDate()
      this.getItemList()
    },

    setParams () {
      const { date, shopIds, userName, status, businessStatus } = this.searchData.params
      const [beginCreateTime, endCreateTime] = date || []
      return {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        beginCreateTime: beginCreateTime ? beginCreateTime : null,
        endCreateTime: endCreateTime ? endCreateTime : null,
        shopIds: shopIds && shopIds.length > 0 ? shopIds[shopIds.length - 1] : null,
        userName,
        status,
        businessStatus
      }
    },
    getItemList () {
      const data = this.setParams()
      this.$api.merchant.getPayLogListApi(data).then(({ code, data }) => {
        if (code !== 200) return
        this.pager.count = data.total || 0
        this.tableData = data.list
      })
    },
    /**
     * 获取选择店铺列表
     */
    getShopList () {
      this.$api.shopapi.getShopList()
        .then(({ data, code }) => {
          if (code == 200) {
            this.childList = data.childList
            const child = [{
              id: data.id,
              name: data.name,
              childList: data.childList
            }]
            this.searchData.searchItem.forEach(ele => {
              if (ele.type === 'linkageSelect') {
                ele.options = child
              }
            })
          }
        })
    }
  },
};
</script>

<style scoped lang='scss'>
.ticket {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 30px 30px;
  margin-top: -20px;
  box-sizing: border-box;
  width: 100%;

  .header {
    position: relative;
    line-height: 28px;
    display: flex;
    justify-content: flex-end;
  }

  .el-table {
    flex: 1;
    overflow-y: auto;
    margin-top: 20px;
  }
}

.d-paging {
  text-align: center;
}
</style>