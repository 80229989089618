<template>
  <div class="position" v-if="$route.path==='/systemModule/auth/role'">
    <d-search class="d-search" :searchData="searchData" @searchEvent="searchEvent" @resetEvent="resetEvent"/>
    <div class="content">
      <div class="header">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="createRole" v-if="isShow">新建</el-button>
      </div>
      <div class="contentTable">
        <d-lable :tableData='tableData' :columnData='columnData'>
          <template v-slot:handle="{item}">
            <span class="operate" type="primary" v-if="item.canEdit" @click="compile(item)">编辑</span>
            <span class="operate" type="primary" v-if="item.canDelete" @click="removeEvent(item)">删除</span>
          </template>
        </d-lable>
      </div>

      <d-paging class="d-paging" :pager="pager" @change="getItemList"/>
    </div>


    <el-dialog
      custom-class="positionDialog"
      :title="dialog.title"
      min-width = "500"
      :visible.sync="dialog.dialogVisible"
      :destroy-on-close='true'>
      <div class="dialogContent">
        <el-row class="dialogRow">
          <el-col :span="1"  :offset="4" class="title"><span>*</span>部门名称:</el-col>
          <el-col :span="12" class="division">
            <el-input v-model="dialog.postName" placeholder="请输入部门名称"></el-input>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialog.dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
  <router-view v-else/>
</template>

<script>
import DSearch from '../../../components/d-search' 
import DLable from '../../../components/d-table'
import DPaging from '../../../components/d-paging'
export default {
  data(){
    return {
      isShow: false, //隐藏
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      dialog:{
        dialogVisible:false,
        title:null,
        postName:null
      },
      searchData:{
        searchItem:[{
            label:'角色名称',
            value:'roleId',
            type:'commonInput',
            dateType:'month',
            placeholder:'请输入角色名称',
        }],
        searchBtn:{
          queryBtn:true,
          resetBtn:true
        },
        params:{}
      },
      columnData:[
        {prop:'id',label:'id'},
        {prop:'name',label:'角色名称'},
        {prop:'createTime',label:'创建日期'},
        {prop:'operate',label:'操作',type:{}}
      ],
      tableData: []
    }
  },
  computed: {
  },
  components: {
    DSearch,
    DLable,
    DPaging
  },
  created () {
    this.getSysRoleList()
  },
  mounted () {
  },
  methods: {
    searchEvent() {
      this.pager.page = 1;
      this.getSysRoleList()
    },
    resetEvent(){
      this.pager = {
        ...this.pager,
        page:1,
        rows:10,
        count:0
      }
      this.searchData.params.roleId = null
      this.getSysRoleList()
    },
    createRole(){
      this.$router.push({path:'/systemModule/auth/role/create',query: {type:'add'}})
    },
    removeEvent(column){
      this.$api.sysapi.deleteSysRole(column.id)
        .then(({code, msg, data})=>{
          if(code === 200){
             this.$message({
                message: msg,
                type: 'success'
            });
            this.getSysRoleList()
          }else{
            this.$message.error(msg);
          }
        })
    },
    /**
     * 查询角色列表
     */
    getSysRoleList(){
      const obj = {
        pageNum:this.pager.page,
        pageSize:this.pager.rows,
        name:this.searchData.params.roleId,
        status:1
      }
      this.$api.sysapi.getSysRoleList(obj)
        .then(({code, data, msg})=>{
          if(code === 200){
            this.pager.count = data.total
            this.tableData = data.list
          }else{
            this.$message.error(msg);
          }
        })
    },
    /**
     * click 编辑
     */
    compile(column){
       this.$router.push({path:'/systemModule/auth/role/compile',query: {id:column.id}})
    },
    /**
     * 添加或者编辑确定按钮
     */
    dialogEvent(){

    },
    /**
     * 查询请求
     */
	  getItemList() {
      this.getSysRoleList()
    },
  },
  watch: {
    '$route'(to,from){
      if(to.path==='/systemModule/auth/role'){
        this.getSysRoleList()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .position{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    .d-search{
      background-color: #fff;
      border-radius: 5px;
    }
    .content{
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: 100%;
      .contentTable{
        height: 100%;
        overflow-y: auto;
      }
      .el-table {
        flex: 1;
        overflow-y: auto;
      }
      .header{
        margin: 10px 20px;
        box-sizing: border-box;

        .el-button{
          float: right;
        }
      }
      .d-paging{
        margin-top: 20px;
      }
    }

    .dialogRow{
      display: flex;
      margin-top: 20px;
      .title{
        min-width: 80px;
        line-height: 40px;
        position: relative;
        span{
          position: absolute;
          left: -10px;
          top: 2px;
          color: red;
          margin-right: 4px;
        }
      }
    }
  }
  .operate{
    display: inline-block;
    margin: 0 5px;
    text-decoration: underline;
    color: #5c6be8;
    cursor: pointer;
  }
  .operate:first-child {
    margin-left: 0;
  }
</style>
