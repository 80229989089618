<template>
  <div class="createUser">
    <div class="header">
      <div class="headerTitle">
        <h4>用户信息</h4>
      </div>
      <div class="headerHint"><span>*</span>为必填项</div>
    </div>
    <div class="content" style="display:flex">
      <div class="contentLeft">
        <!-- class="title" -->
        <div  style="padding: 15px;text-align: left;">场馆</div>
        <div v-for="item in venuList" :key="item.marketName" style="margin-bottom: 15px;color: #5D68FD;">
          {{item.marketName}}
        </div>
        <div v-if="venuList.length==0">暂无数据</div>
      </div>
      <el-form style="flex:1" :model="userInfo" ref="userInfoForm" :rules="userInfoRules">
        <el-row>
          <el-col :span="6">
            <el-form-item prop="mobile">
              <div class="title">手机号</div>
              <el-input size="small" v-model="userInfo.mobile" placeholder="请输入手机号" :disabled="$route.query.type==='compile'"></el-input>
            </el-form-item>
            <el-form-item class="spacing" prop="name">
              <div class="title">姓名</div>
              <el-input size="small" v-model="userInfo.name" placeholder="请输入姓名" auto-complete="new-password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="2">
            <el-form-item prop="password">
              <div class="title">密码</div>
              <el-input size="small" v-model="userInfo.password" placeholder="请输入密码"  show-password auto-complete="new-password"></el-input>
            </el-form-item>
            <el-form-item class="spacing">
              <div class="title">性别</div>
              <div class="el-input">
                <el-radio v-model="userInfo.sex" label="1">男</el-radio>
                <el-radio v-model="userInfo.sex" label="2">女</el-radio>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="2">
            <div  class="avatar-uploader" @click="uploadImg">
              <img v-if="userInfo.imageUrl" :src="userInfo.imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top:40px;">
          <el-col :span="6">
            <el-form-item prop="email">
              <div class="nonTitle">邮箱</div>
              <el-input size="small" v-model="userInfo.email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
          </el-col>
  <!--        <el-col :span="6" :offset="2">-->
  <!--          <el-form-item prop="deptIds">-->
  <!--            <div class="title">部门</div>-->
  <!--            <department multiple v-model="userInfo.deptIds" style="margin-top: 10px;"></department>-->
  <!--              &lt;!&ndash; <el-select v-model="dialog.shop" placeholder="请选择店铺" @click.native="innerVisible = true" disabled></el-select> &ndash;&gt;-->
  <!--          </el-form-item>-->
  <!--        </el-col>-->
          <!-- <el-col :span="6" :offset="2">
            <el-form-item prop="role">
              <div class="title">角色</div>
              <el-select size="small" v-model="userInfo.role" placeholder="请选择" :disabled="userInfo.isMarketAdmin">
                <el-option
                  v-for="item in divisionOptions"
                  :key="item.id"
                  :label="item.roleName"
                  :disabled="!item.canAssign"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row style="margin-top:40px;">
          <el-col :span="14">
            <div>
              <div class="nonTitle">备注</div>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="userInfo.comment">
              </el-input>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>


    <div class="footer">
      <el-button size="medium" @click="$router.push({path:'/systemModule/auth/user'})">取消</el-button>
      <el-button size="medium" type="primary" @click="sysUserUpdate">确定</el-button>
    </div>

    <form ref="fileInputForm" style="width: 0;height: 0;">
      <input style="width: 100%;height: 100%; display: none" multiple type="file" @change="fileInputChange" ref="fileInput" accept="image/gif, image/jpeg, image/png, audio/*">
  	</form>
      <el-dialog
        title="选择部门"
        :visible.sync="innerVisible"
        append-to-body
        custom-class="departmentDialog">
        <div class="innerContent">
          <el-tabs value="first" key='first' type="card">
            <el-tab-pane label="选择部门" name="first">
               <el-tree ref="vuetree" :data="data" :props="defaultProps" @node-click="handleDepartmentClick" highlight-current node-key="id" :default-expanded-keys="[15]" :current-node-key="currentKey"></el-tree>
            </el-tab-pane>
          </el-tabs>
          <div class="innerDialogFooter">
            <el-button @click="innerVisible = false">取 消</el-button>
            <el-button type="primary" @click="setDept">确定</el-button>
          </div>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import Department from '../../../components/department.vue'
import {validatePhone, validateEmail,validateUserPwd} from "../../../utils/validator";
export default {
  data(){
    return {
      venuList: [],
      innerVisible:false,
      userInfo:{  // 用户信息对象
        sex:'1',
        // deptIds:[]
      },
      dialog:{
        shop:null
      },
      currentKey:null,
      data:[],
      defaultProps: {
        children: 'child',
        label: 'deptName'
      },
      userInfoRules:{
        mobile:[
          {required:true,message:'请输入手机号',trigger:'blur'},
          {validator:validatePhone,trigger:'blur'}
        ],
        name:[
          {required:true,message:'请输入姓名',trigger:'blur'}
        ],
        password:[
          {required:true,message:'请输入密码',trigger:'blur'},
          {validator:validateUserPwd,trigger: 'blur'}
        ],
        // deptIds:[
        //   {required:true,message:'请选择部门',trigger:'change'},
        // ],
        role:[
          {required:true,message:'请分配角色',trigger:'change'}
        ],
        email:[
          {validator:validateEmail,trigger:'blur'}
        ]
      },
      divisionOptions:[],
      positionOptions:[],
      selectDept:null,//选中部门
      primaryDept:null, // 预选部门
    }
  },
  computed: {
  },
  components: {
    Department
  },
  created () {
    // this.getSysDeptTree()
    this.getSysRoleList()
    this.getSysPositionList()
    if(this.$route.query.type === 'compile'){
      this.getUserInfo()
    }
    if(this.$route.query.departmentID){
      this.userInfo.deptIds.push(this.$route.query.departmentID)
    }
    // 获取场馆列表  this.$route.query.id
    this.$api.sysapi.getMarketUser({
      userId: this.$route.query.id,
      pageSize: -1,
      pageNum: 1
    }).then(res=>{
      if(res.code==200){
        this.venuList = res.data.list || []
      }
    })
  },
  mounted () {
    this.$nextTick(()=>{
      if(this.$route.query.type === 'compile'){
        this.$parent.$parent.$parent.$refs.title.title='编辑用户';
        this.userInfoRules.password=[
          {validator:validateUserPwd,trigger: 'blur'}
        ]
      }
    })
  },
  methods: {
    uploadImg(){
      this.$refs.fileInput.click()
    },
    /**
     * 选择部门
     */
    handleDepartmentClick(val){
      this.primaryDept = val
    },
    /**
     * 确定
     */
    setDept(){
      this.innerVisible = false
      this.selectDept = this.primaryDept
      this.dialog.shop = this.selectDept.deptName
    },
    /**
     * 上传图片
     */
    fileInputChange() {
      const file = this.$refs.fileInput.files[0]
      if(file){
        var formData = new FormData();
        formData.append(`file`, file)
        this.$api.sysapi.uploadFile(formData,2)
          .then(({code, data, msg})=>{
            if(code === 200){
              this.userInfo = {
                ...this.userInfo,
                imageUrl:data
            }
          }
        })
      }

    },
    sysUserUpdate(){
      this.$refs.userInfoForm.validate(valid=>{
        if(valid){
          const obj = {
            id:this.$route.query.type === 'compile' ? this.$route.query.id : null,
            job_number:this.userInfo.jobId,
            sex:+this.userInfo.sex === 1 ? 'M' : 'F',
            mobile:this.userInfo.mobile,
            name:this.userInfo.name,
            password:this.userInfo.password,
            email:this.userInfo.email,
            icon:this.userInfo.imageUrl,
            // roleIds:[this.userInfo.role],
            // deptIds:this.userInfo.deptIds,
            comment:this.userInfo.comment,
          }
          this.$api.sysapi.sysUserUpdate(obj)
              .then(({code, data, msg})=>{
                if(code === 200){
                  this.$router.push({path:'/systemModule/auth/user'})
                }else{
                  this.$message.error(msg);
                }
              })
        }
      })
    },

    /**
     * 查询部门树
     */
    getSysDeptTree(){
      this.$api.sysapi.getSysDeptTree()
        .then(({code , data , msg})=>{
          if(code === 200){
            this.data = [data]
          }else{
            this.$message.error(msg);
          }
        })
    },
    /**
     * 查询角色列表
     */
    getSysRoleList(){
      const obj = {
        pageNum:-1,
        status:1
      }
      this.$api.sysapi.getSysRoleList(obj)
        .then(({code, data, msg})=>{
          if(code === 200){
            this.divisionOptions = data.list
            .filter(e=>e.isShow)
            .map((item)=>({
              ...item,
              roleName:item.name
            }))
          }
        })
    },
    /**
     * 用户详情
     */
    getUserInfo(){
      this.$api.sysapi.getUserInfo(this.$route.query.id)
        .then(({code, msg, data})=>{
          if(code === 200){
            this.drawer = true
            this.userInfo = {
              ...data,
              imageUrl:data.icon,
              status:data.status === 0 ? '离职' : data.status === 1 ? '在职' : data.status === 2 ? '未激活' : data.status === 3 ? '离职（未退押金）' : '',
              sex:data.sex === 'M' ? '1' : '2',
              // role:data.userRoleList.map((item)=>item.roleId)[0]
            }
            console.log(this.userInfo.role)
            this.dialog.shop = data.deptName
            this.currentKey = data.deptId
            // this.deptIds = data.deptIds;
          }else{
            this.$message.error(msg);
          }
        })
    },
    /**
     * 查询职位列表
     */
    getSysPositionList(){
      const obj= {
        pageNum:-1,
        status:1
      }
      this.$api.sysapi.getSysPositionList(obj)
        .then(({code, data, msg})=>{
          if(code === 200){
            this.positionOptions = data.list
          }
        })
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
  .createUser{
    width: 100%;
    flex: 1;
    overflow-y: auto;
    background-color: #fff;
    .header{
      flex-shrink: 0;
      height: 55px; 
      border-bottom: 1px solid rgba(233, 233, 233, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;
      box-sizing: border-box;
      .headerTitle{
        display: flex;
      } 
      .headerHint{
        font-size: 14px;
        color: #999999;
        span{
          position: relative;
          top: 3px;
          left: -3px;
          color: red;
        }
      }
    }
    .content{
      font-size: 14px;
      color: rgba(0, 0, 0, .85);
      padding: 30px 30px 80px 30px;
      flex: 1;
      box-sizing: border-box;
      .contentLeft{
        overflow: auto;
        text-align: center;
        width: 160px;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        margin-right: 20px;
      }
      .title,.nonTitle{
        line-height: 16px;
        white-space:nowrap;
        display: flex;
      }
      .title::before{
        content: '*';
        color: red;
        margin-right: 2px;

      }
      .el-input,.el-select,.el-textarea{
        display: block;
        margin-top: 10px;
        display:flex;
        align-items: center;
      }
      .spacing{
        margin-top: 40px;
      }
      .telephone{
        display: flex;
        align-items: center;
        .segment{
          position: relative;
          top: 5px;
          margin: 0 2px;
        }
      }
    }
    .el-icon-plus{
      border: 1px #ccc dashed;
    }
    .footer{
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 56px;
      box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.2);
      text-align: right;
      padding-right: 40px;
      box-sizing: border-box;
      background-color: #fff;
      .el-button{
        margin-top: 8px;
      }
    }
  }
  .innerContent{
    .innerDialogFooter{
        height: 60px;
        text-align: right;
        .el-button{
          margin-top: 10px;
          
        }
    }
  }
  ::v-deep .el-cascader{
    width: 100%;
  }
</style>
