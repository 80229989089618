<template>
    <div class="authority">
      <div class="header"><h4>权限划分</h4></div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="后台权限" name="first">
            <el-tree
              :data="backstageData"
              ref="treeB"
              show-checkbox
              node-key="id"
              :default-expand-all="true"
              :props="defaultProps"
              @check-change="handleCheckChange_Backstage"
              :default-checked-keys="compileArr">
            </el-tree>
        </el-tab-pane>
        <el-tab-pane label="前台权限" name="second">
          <el-tree
              :data="front"
              ref="tree"
              show-checkbox
              node-key="id"
              :default-expand-all="true"
              :props="defaultProps"
              @check-change="handleCheckChange_Front"
              :default-checked-keys="compileArr">
            </el-tree>
        </el-tab-pane>
      </el-tabs>
    </div>
</template>

<script>

export default {
  name: 'background',
  props:['sysArr'],
  data(){
    return {
      activeName:'first',
      backstageData: [],
      front:[],
      defaultProps: {
        children: 'child',
        label: 'name'
      },
      frontArr:[], // 选中的
      backstageArr:[], // 选中的
    } 
  },
  computed: {
  },
  components: {
  },
  created () {
    this.getSysModuleTree()
    this.compileArr = this.sysArr
  },
  mounted () {
  },
  methods: {
    getSysModuleTree(){
     this.$api.sysapi.getSysModuleTree()
      .then(({code, data, msg})=>{
        if(code === 200){
          const _frontMenus = this.filterMenu([data.child[1]])
          const _backMenus = this.filterMenu([data.child[0]]).map(e=>(e.child.sort((a,b)=>{
            return a.code - b.code
          }),e))
          this.front = [{
            name:_frontMenus[0].name,
            id:_frontMenus[0].id,
            child:_frontMenus[0].child
          }]
          this.backstageData = [{
            name:_backMenus[0].name,
            id:_backMenus[0].id,
            child:_backMenus[0].child
          }]

        }
      })
    },
    filterMenu(menus){
      const user = JSON.parse(localStorage.getItem('userInfo'));
      if(!user.marketId) return menus;
      const _filter = (_menu)=>{
        const result = [];
        // canHandle--能不能操作
        _menu.forEach(e=>{
          if(e.child){
            e.child = _filter(e.child)
          }
          e.disabled = !e.canHandle
          result.push(e)
        })
        return result
      }
     return _filter(menus)
    },
    handleCheckChange_Front() {
      this.frontArr = []
      let res = this.$refs.tree.getCheckedNodes()
      let arr = []
      res.forEach((item) => {
        arr.push(item.id)
      })
      this.frontArr = Array.from(new Set([...this.$refs.tree.getHalfCheckedKeys(),...arr]))
    },
    handleCheckChange_Backstage(){
      this.backstageArr = []
      let res = this.$refs.treeB.getCheckedNodes()
      let arr = []
      res.forEach((item) => {
        arr.push(item.id)
      })
      this.backstageArr = Array.from(new Set([...this.$refs.treeB.getHalfCheckedKeys(),...arr]))
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
  .header{
    height: 55px; 
    border-bottom: 1px solid rgba(233, 233, 233, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;
    .headerTitle{
      display: flex;
      span{
        color: #999999;
        font-size: 14px;
      }
    } 
    .headerHint{
      font-size: 14px;
      color: #999999;
      span{
        position: relative;
        top: 3px;
        left: -3px;
        color: red;
      }
    }
  }
  .checkAll{
    margin: 10px 0 20px 0;
  }
  .authority{
    .el-tabs{
      padding: 0 30px;
    }
    .el-row .el-col:first-child{
      margin-top: 10px;
    }
    .createRoleCheckboxGroup{
      .el-checkbox{
          color: #999;
          margin-top: 10px;
      }
    }
    .el-divider--horizontal{
      margin: 30px 0;
      background: 0 0;
      border-top: 1px dashed #e8eaec;
    }
  }
</style>
